import rest from '@feathersjs/rest-client';
import feathers from '@feathersjs/client';
import { Service } from '@feathersjs/feathers';
import { Customer } from '../model/customer';
import { Vehicle } from '../model/vehicle';
import {Organization} from "../model/organization";

const app = feathers();

const restClient = rest(process.env.REACT_APP_BACKEND_URL ?? 'https://spv-backend.stg.sungazer.io');
app.configure(restClient.fetch(window.fetch));
app.configure(feathers.authentication({ storage: window.localStorage, storageKey: 'spv-jwt' }));

app.reAuthenticate().then((p) => {localStorage.setItem('spv-organizationId', p.user.organization);}).catch((e) => console.log(e));

const { authentication } = app;
const users = app.service('users');
const customers = app.service('customers') as Service<Customer>;
const inspections = app.service('vehicle-inspections');
const vehicles = app.service('vehicles') as Service<Vehicle>;
const organizations = app.service('organizations') as Service<Organization>;

export { authentication, users, customers, inspections, vehicles, organizations };
