import React from 'react'
import ReportCard from './reportCards.component'

export default class ReportList extends React.PureComponent<IReportListProps, IReportListState> {

  constructor(props: IReportListProps) {
    super(props);
    this.state = {
      // TODO
    }
  }

  // If you need 'shouldComponentUpdate' -> Refactor to React.Component
  // Read more about component lifecycle in the official docs:
  // https://reactjs.org/docs/react-component.html

  /*
  public shouldComponentUpdate(nextProps: IMyPageProps, nextState: IMyPageState) {
    // invoked before rendering when new props or state are being received.
    return true // or prevent rendering: false
  } */

  static getDerivedStateFromProps:
  React.GetDerivedStateFromProps<IReportListProps, IReportListState> = (props:IReportListProps, state: IReportListState) => {
    // invoked right before calling the render method, both on the initial mount and on subsequent updates
    // return an object to update the state, or null to update nothing.
    return null
  }

  public getSnapshotBeforeUpdate(prevProps: IReportListProps, prevState: IReportListState) {
    // invoked right before the most recently rendered output is committed
    // A snapshot value (or null) should be returned.
    return null
  }

  componentDidUpdate(prevProps: IReportListProps, prevState: IReportListState, snapshot: ITemplateNameSnapshot) {
    // invoked immediately after updating occurs. This method is not called for the initial render.
    // will not be invoked if shouldComponentUpdate() returns false.
  }

  render() {
    return (
      <div>
        {
          this.props.vehicles.map((vehicle: { name: string; plate: string; revisione1: string}) => (
            <ReportCard key={vehicle.plate} plate={vehicle.plate} revision1={vehicle.revisione1} name={vehicle.name} />
          ))
        }
      </div>
    )
  }
}

interface ICardListVehicles {
  name: string;
  plate: string;
  revisione1:string;
}

interface IReportListProps {
  vehicles: ICardListVehicles[];
}

interface IReportListState {
  // TODO
}

interface ITemplateNameSnapshot {
  // TODO
}