import React, {FunctionComponent, useEffect} from 'react'
import './clientDetails.scss'
import {
    useParams
} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Customer} from '../../../../model/customer';
import {customers} from '../../../../backend/backend';
import VehicleList from '../../../../components/vehicles/vehiclescardlist.component';
import {cleanCustomer, loadCustomer, selectCustomer} from '../../../../features/customers/customersSlice';
import {useAppDispatch} from "../../../../redux/hooks";

const ClientDetails: FunctionComponent = () => {
    const {id} = useParams<{ id?: string }>();
    const customerState = useSelector(selectCustomer);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (id) {
            dispatch(loadCustomer({id}));
        }
    }, []);
    useEffect(() => () => {
        if (id) {
            dispatch(cleanCustomer());
        }
    }, []);
    return (
        <div className="sm:mx-6 flex-col">
            <div className="flex-row flex sm:mb-9 print:mb-4">
                <h1>
                    {customerState?.name}
                </h1>
            </div>
            <div className="flex-row justify-between hidden print:flex w-full mb-4">
                <div className="flex-col flex">
                    <label htmlFor="address">Indirizzo</label>
                    <span className="text-gray-600" id="address">{customerState?.address}</span>
                </div>
                <div className="flex-col flex">
                    <label htmlFor="email">Email</label>
                    <span className="text-gray-600 w-12" id="email">{customerState?.email}</span>
                </div>
                <div className="flex-col flex">
                    <label htmlFor="phone" >Telefono</label>
                    <span className="text-gray-600 " id="phone">{customerState?.phoneNumber}</span>
                </div>
            </div>
            <h6 className="text-primary-700">Veicoli</h6>
            <VehicleList customerId={id!} customerName={customerState?.name}/>
        </div>

    );
}
export default ClientDetails;
