import React from 'react'
import './Reports.scss'
import { RouteComponentProps } from 'react-router-dom'
import NavBar from '../../../components/navbar.component';
import ReportList from '../../../components/reports/reportList.component';

export default class Reports extends React.PureComponent<IReportsProps, IReportsState> {

  constructor(props: IReportsProps) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      customers: [
        {
          name: 'Acme Srl',
          vehiclesNumber: 0,
          revisionExpiringin1: 10,
          revisionExpiringin2: 3,
        },
        {
          name: 'Pino Snc',
          vehiclesNumber: 0,
          revisionExpiringin1: 3,
          revisionExpiringin2: 4,
        },
        {
          name: 'Rossi Spa',
          vehiclesNumber: 0,
          revisionExpiringin1: 1,
          revisionExpiringin2: 8,
        },
        {
          name: 'Tizio Srl',
          vehiclesNumber: 0,
          revisionExpiringin1: 8,
          revisionExpiringin2: 9,
        }
      ],
      // eslint-disable-next-line react/no-unused-state
      vehicles: [
        {
          name: 'Acme Srl',
          plate: 'AA000AA',
          revisione1: '2021-01-16',
        },
        {
          name: 'Pino Snc',
          plate: 'BB000BB',
          revisione1:'2021-06-12',
        },
        {
          name: 'Rossi Spa',
          plate: 'CC000CC',
          revisione1: '2021-08-13',
        }
      ],
      // eslint-disable-next-line react/no-unused-state
      searchField: '',
      searchName:'',
      searchPlate:'',
      searchRevision:'',
    }
  }

  // If you need 'shouldComponentUpdate' -> Refactor to React.Component
  // Read more about component lifecycle in the official docs:
  // https://reactjs.org/docs/react-component.html

  /*
  public shouldComponentUpdate(nextProps: IMyPageProps, nextState: IMyPageState) {
    // invoked before rendering when new props or state are being received.
    return true // or prevent rendering: false
  } */

  static getDerivedStateFromProps: React.GetDerivedStateFromProps<IReportsProps, IReportsState> = (props:IReportsProps, state: IReportsState) => {
    // invoked right before calling the render method, both on the initial mount and on subsequent updates
    // return an object to update the state, or null to update nothing.
    return null
  }

  public getSnapshotBeforeUpdate(prevProps: IReportsProps, prevState: IReportsState) {
    // invoked right before the most recently rendered output is committed
    // A snapshot value (or null) should be returned.
    return null
  }

  componentDidUpdate(prevProps: IReportsProps, prevState: IReportsState, snapshot: ITemplateNameSnapshot) {
    // invoked immediately after updating occurs. This method is not called for the initial render.
    // will not be invoked if shouldComponentUpdate() returns false.
  }

  render() {
    const filteredVehicles = this.state.vehicles.filter(vehicle =>
      vehicle.plate.toLowerCase().includes(this.state.searchPlate.toLowerCase())
      &&
      vehicle.name.toLowerCase().includes(this.state.searchName.toLowerCase())
      &&
      vehicle.revisione1.toLowerCase().includes(this.state.searchRevision.toLowerCase())
    );
    return (
      <div className="bg-gray-50">
        <NavBar />
        <div className="flex-row justify-center flex">
          <div className="flex-col flex w-1/4">
            <button type="button" className="NewClientButton">
              STAMPA
            </button>
          </div>
          <div className="flex-col xl:w-1/2 max-w-screen-lg flex bg-white min-h-screen">
            <div className="flex-row flex sm:mt-4 sm:mb-9">
              <h2 className="text-3xl font-bold text-primary-700 flex sm:mx-6 flex-col w-3/4">
                Report
              </h2>
              <div className="flex-col flex sm:mr-16">
                <select name="orderByClients" id="orderClients" className="rounded-md">
                  <option value="default">Ordina</option>
                  <option value="opzione2">Opzione 2</option>
                  <option value="opzione3">Opzione 3</option>
                </select>
              </div>
            </div>
            <div className="flex flex-row sm:w-3/4 max-w-2xl mt-4 mx-auto justify-center container bg-gray-100 font-bold shadow-sm text-gray-500">
              <div className="flex flex-col mx-auto mt-3 mb-3">
                TARGA
              </div>
              <div className="flex flex-col mx-auto mt-3 mb-3">
                CLIENTE
              </div>
              <div className="flex flex-col mx-auto mt-3 mb-3">
                SCADENZA REVISIONE
              </div>
            </div>
            <ReportList vehicles={filteredVehicles} />
          </div>
          <div className="flex-col flex w-1/4">
            <div className="flex-row flex sm:mt-4">
              <h2 className="text-3xl font-bold text-primary-700 flex mx-8 sm:mb-12">
                Filtri
              </h2>
            </div>
            {/* <FilterSearch */}
            {/*  handleChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ searchPlate: event.target.value })} */}
            {/*  placeHolder="Targa" */}
            {/* /> */}
            {/* <FilterSearch */}
            {/*  handleChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ searchName: event.target.value })} */}
            {/*  placeHolder="Cliente" */}
            {/* /> */}
            {/* <FilterSearch */}
            {/*  handleChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ searchRevision: event.target.value })} */}
            {/*  placeHolder="Prima revisione in scadenza" */}
            {/* /> */}
            {/* <FilterSearch */}
            {/*  handleChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ })} */}
            {/*  placeHolder="Ultima revisione in scadenza" */}
            {/* /> */}
          </div>
        </div>
      </div>
    )
  }
}

interface IReportsProps extends RouteComponentProps<{ name: string }> {
}

interface IReportsCustomers {
  name: string;
  revisionExpiringin1: number;
  revisionExpiringin2: number;
  vehiclesNumber:number;
}

interface IReportsVehicles {
  plate: string;
  revisione1: string;
  name: IReportsCustomers['name'];
}

interface IReportsState {
  customers: IReportsCustomers[];
  vehicles: IReportsVehicles[];
  searchField: string;
  searchPlate:string;
  searchName:string;
  searchRevision:string;
}

interface ITemplateNameSnapshot {
  // TODO
}
