import React, { FunctionComponent } from 'react';
import ClientCreateModal from '../components/modals/ClientCreateModal';
import VehicleCreaterModal from '../components/modals/VehicleCreateModal';

export enum Modals {
  ClientCreate,
  VehicleCreate
}

const modalEnumToComponent: (e: Modals) => FunctionComponent<{closeCallback: () => void}> = (e: Modals) => {
  switch (e) {
    case Modals.ClientCreate:
      return ClientCreateModal;
    case Modals.VehicleCreate:
      return VehicleCreaterModal;
  }
  const def: FunctionComponent = () => {
    return (<div />);
  };
  return def;
}
export default modalEnumToComponent;
