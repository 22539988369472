import React from 'react'
import './reportCards.component.scss'

export default class ReportCard extends React.PureComponent<IReportCardProps, IReportCardState> {
  constructor(props: IReportCardProps) {
    super(props);
    this.state = {
    }
  }

  // If you need 'shouldComponentUpdate' -> Refactor to React.Component
  // Read more about component lifecycle in the official docs:
  // https://reactjs.org/docs/react-component.html

  /*
  public shouldComponentUpdate(nextProps: IMyPageProps, nextState: IMyPageState) {
    // invoked before rendering when new props or state are being received.
    return true // or prevent rendering: false
  } */

  static getDerivedStateFromProps:
  React.GetDerivedStateFromProps<IReportCardProps, IReportCardState> = (props:IReportCardProps, state: IReportCardState) => {
    // invoked right before calling the render method, both on the initial mount and on subsequent updates
    // return an object to update the state, or null to update nothing.
    return null
  }

  public getSnapshotBeforeUpdate(prevProps: IReportCardProps, prevState: IReportCardState) {
    // invoked right before the most recently rendered output is committed
    // A snapshot value (or null) should be returned.
    return null
  }

  componentDidUpdate(prevProps: IReportCardProps, prevState: IReportCardState, snapshot: ICardComponentSnapshot) {
    // invoked immediately after updating occurs. This method is not called for the initial render.
    // will not be invoked if shouldComponentUpdate() returns false.
  }

  render() {
    return (
      <div className="flex flex-row sm:w-3/4 max-w-2xl mx-auto mr-auto justify-center container bg-gray-50 font-medium shadow-md text-gray-500">
        <div className="flex flex-col mx-auto mt-3 mb-3">
          {this.props.plate}
        </div>
        <div className="flex flex-col mx-auto mt-3 mb-3">
          {this.props.name}
        </div>
        <div className="flex flex-col mx-auto mt-3 mb-3">
          {this.props.revision1}
        </div>
      </div>
    )
  }
}

interface IReportCardProps {
  plate:string;
  revision1:string;
  name:string;
}

interface IReportCardState {
  // TODO
}

interface ICardComponentSnapshot {
  // TODO
}
