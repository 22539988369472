import React from 'react';
import './Auth.scss';
import { Route, RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import Login from './Login/Login';

export default class Auth extends React.PureComponent<IAuthProps, IAuthState> {
  path?: string;

  constructor(props: IAuthProps) {
    super(props);
    this.state = {
      // name: this.props.history.location.pathname.substring(
      //   1,
      //   this.props.history.location.pathname.length
      // ).replace('/', '')
    };
  }

  // If you need 'shouldComponentUpdate' -> Refactor to React.Component
  // Read more about component lifecycle in the official docs:
  // https://reactjs.org/docs/react-component.html

  /*
  public shouldComponentUpdate(nextProps: IMyPageProps, nextState: IMyPageState) {
    // invoked before rendering when new props or state are being received.
    return true // or prevent rendering: false
  } */

  static getDerivedStateFromProps: React.GetDerivedStateFromProps<IAuthProps, IAuthState> = (props: IAuthProps, state: IAuthState) => {
    // invoked right before calling the render method, both on the initial mount and on subsequent updates
    // return an object to update the state, or null to update nothing.
    return null;
  };

  public getSnapshotBeforeUpdate(prevProps: IAuthProps, prevState: IAuthState) {
    // invoked right before the most recently rendered output is committed
    // A snapshot value (or null) should be returned.
    return null;
  }

  componentDidUpdate(prevProps: IAuthProps, prevState: IAuthState, snapshot: IAuthSnapshot) {
    // invoked immediately after updating occurs. This method is not called for the initial render.
    // will not be invoked if shouldComponentUpdate() returns false.
  }

  render() {
    return (
      <Switch>
        <Route exact path={this.props.match.path}>
          <Redirect to={`${this.props.match.path}/login`} />
        </Route>
        <Route path={`${this.props.match.path}/login`} component={Login} />
      </Switch>
    );
  }
}

interface IAuthProps extends RouteComponentProps<{ name: string }> {
  // TODO
}

interface IAuthState {
  // name: string
}

interface IAuthSnapshot {
  // TODO
}
