import React, {FunctionComponent, useCallback, useEffect} from 'react'
import './vehiclescardlist.component.scss'
import InfiniteScroll from 'react-infinite-scroll-component';
import {Link, useLocation} from 'react-router-dom';
import VehicleCard from './vehiclescard.component';
import {Vehicle} from '../../model/vehicle';
import {Customer} from "../../model/customer";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
    cleanVehicles,
    loadVehicles,
    selectVehicles,
    selectVehiclesFilter,
    selectVehiclesPagination,
    selectVehiclesSorting
} from "../../features/vehicles/vehiclesSlice";
import {parse} from "query-string";
import {showModal} from "../../features/modal/modalSlice";
import {Modals} from "../../utils/modals";

const VehicleList: FunctionComponent<{ customerId?: string, customerName?: string }> = (props) => {

    const vehiclesState = useAppSelector<Vehicle[]>(selectVehicles);
    const paginationState = useAppSelector(selectVehiclesPagination);
    const filters = useAppSelector(selectVehiclesFilter);
    const sorting = useAppSelector(selectVehiclesSorting);
    const dispatch = useAppDispatch();

    const location = useLocation();

    const isPrinting = parse(location.search).print === '1';

    useEffect(() => () => {
        dispatch(cleanVehicles());
    }, []);

    const loadNext = useCallback((): void => {
        dispatch(loadVehicles({customerId: props.customerId, loadAll: false}));
    }, []);

    useEffect(() => {
        dispatch(loadVehicles({customerId: props.customerId, loadAll: isPrinting}));
    }, [filters, sorting, isPrinting]);

    const showModalEdit = (vehicle:Vehicle) => {
        dispatch(showModal({ modal: Modals.VehicleCreate, props:  { vehicle } }));
    };

    return (
        <InfiniteScroll next={!isPrinting ? loadNext : () => {
        }} hasMore={!isPrinting && vehiclesState.length < paginationState.total} loader={<h4>Loading...</h4>}
                        dataLength={vehiclesState.length}>
            {
                vehiclesState.map((vehicle) => (
                    // eslint-disable-next-line no-underscore-dangle
                    <div key={vehicle._id}>
                        {/*<Link to={`/app/vehicles/${vehicle._id}`} key={vehicle._id}>*/}
                        <div onClick={() => {
                            showModalEdit(vehicle);
                        }} className="cursor-pointer">
                            <VehicleCard customerName={props.customerName ?? (vehicle.customer as Customer).name}
                                         plate={vehicle.plate!} revisione1={vehicle.nextInspectionDate!}
                            />
                        </div>
                        {/*</Link>*/}
                        <hr className="hidden print:block"/>
                    </div>
                ))
            }
        </InfiniteScroll>
    )
}

export default VehicleList
