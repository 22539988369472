import React, {FunctionComponent} from 'react';
import './Clients.scss';
import ClientsList from '../../../components/clients/clientsList.component';
import {CustomerSorting} from '../../../utils/customerSorting';
import {setSorting} from '../../../features/customers/customersSlice';
import {useAppDispatch} from "../../../redux/hooks";


const Clients: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    return (
        <div className="sm:mx-6">
            <div className="flex-row flex sm:mb-9">
                <h2 className="text-3xl font-bold text-primary-700 flex flex-col w-3/4">
                    Clienti
                </h2>
                <div className="flex-col flex">
                    <select
                        name="orderByClients"
                        id="orderClients"
                        className="rounded-md"
                        onChange={({target}) => dispatch(setSorting(target.value as CustomerSorting))}
                    >
                        <option value={CustomerSorting.Name}>Alfabetico</option>
                        <option value={CustomerSorting.Vehicles}>Numero di veicoli</option>
                    </select>
                </div>
            </div>
            <ClientsList/>
        </div>
    );
};

export default Clients;
