import React, {FunctionComponent} from 'react'
import './vehiclescard.component.scss'
import RevisionCheck from '../revisionCheck.component';


const VehicleCard: FunctionComponent<IVehicleCardProps> = (props) => {
    return (
        <div
            className="mx-auto flex-row flex mt-4 mb-6 py-6 print:py-0 print:mt-1 mr-auto w-full bg-white-pure rounded-md max-w-3xl transform hover:scale-105 shadow-sm print:shadow-none print:transform-none">
            <div className="flex-col flex justify-center mx-4">
                <RevisionCheck revisione1={props.revisione1} />
            </div>
            <div className="flex-col flex mr-auto">
                <div className="text-2xl print:text-base flex text-gray font-bold mt-4 print:mt-0 mx-5">
                    {props.plate}
                </div>
                <div className="text-base print:text-xs font-normal flex text-gray-400 mt-2 print:mt-0 mb-4 print:mb-0 mx-5">
                    {props.customerName}
                </div>
            </div>
            <div className="flex flex-row space-x-2 mx-4 items-center">
                <span className="text-gray-500">
                    Scadenza:
                </span>
                <span className="font-bold text-gray-700">
                    {props.revisione1}
                </span>
            </div>
        </div>
    )
}

export default VehicleCard;


interface IVehicleCardProps {
  customerName?: string;
  plate: string;
  revisione1: Date;
}
