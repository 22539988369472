import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../redux/store';
import { Modals } from '../../utils/modals';

// Define a type for the slice state
interface ModalState {
  modal?: Modals,
  props?: never
}

// Define the initial state using that type
const initialState: ModalState = {
};

export const modalSlice = createSlice({
  name: 'modal',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<{modal: Modals, props: any}>) => {
      return { ...state, modal: action.payload.modal, props: action.payload.props };
    },
    removeModal: (state) => {
      return { ...state, modal: undefined, props: undefined };
    },
  },
});

export const { showModal, removeModal } = modalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectShowBackdrop = (state: RootState) => state.modal.modal != null && state.modal.modal != undefined;
export const selectModal = (state: RootState) => state.modal.modal;
export const selectModalProps = (state: RootState) => state.modal.props;

export default modalSlice.reducer;

