import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';
// import FilterSearch from '../../../components/clients/filterSearch.component';
import {
  selectCustomersFirstInspectionFilter,
  selectCustomersNameFilter, setCustomerFirstInspectionFilter, setCustomerNameFilter
} from '../../../features/customers/customersSlice';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import history from "../../../utils/history";
import {parse, stringify} from "query-string";
import {useLocation} from "react-router-dom";

const ClientsRightSidebar: FunctionComponent = () => {
  const initialName = useAppSelector(selectCustomersNameFilter);
  const initialFirstInspection = useAppSelector(selectCustomersFirstInspectionFilter);
  const [name, setName] = useState(initialName);
  const [firstInspection, setFirstInspection] = useState(initialFirstInspection);

    const location = useLocation();


    // Debounce callback
  const debouncedSetName = useDebouncedCallback(
    // function
    (value) => {
        history.push({search: stringify({...parse(location.search), customerName: value})})
        dispatch(setCustomerNameFilter(value))
    },
    // delay in ms
    300
  );
  const debouncedSetFirstInspection = useDebouncedCallback(
    // function
    (value) => {
        history.push({search: stringify({...parse(location.search), customerFirstInspection: moment(value).format('YYYY-MM-DD')})})
        dispatch(setCustomerFirstInspectionFilter(value))
    },
    // delay in ms
    300
  );
  const dispatch = useAppDispatch();
  return (
      <div className="flex flex-col px-6 space-y-12">
          <h2>Filtri</h2>
          <div className="flex flex-col space-y-4">
              <div className="space-y-2">
                  <label htmlFor="name" className="block ">
                      Nome
                  </label>
                  <input
                      id="name"
                      value={name || ''}
                      className="FilterSearch"
                      placeholder="Nome"
                      type="search"
                      onChange={({ target }) => {
                          setName(target.value);
                          debouncedSetName(target.value);
                      }}
                  />
              </div>
              <div className="space-y-2">
                  <label htmlFor="firstInspection" className="block ">
                      Scadenza entro il
                  </label>
                  <input
                      id="firstInspection"
                      value={firstInspection ? moment(firstInspection).format('YYYY-MM-DD') : ''}
                      className="FilterSearch"
                      placeholder="Revisione in scadenza entro"
                      type="date"
                      onChange={({ target }) => {
                          setFirstInspection(target.valueAsDate);
                          debouncedSetFirstInspection(target.valueAsDate);
                      }}
                  />
              </div>
          </div>
      </div>
  );
}
export default ClientsRightSidebar;
