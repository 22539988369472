import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../redux/store';
import { authentication } from '../../backend/backend';

// Define a type for the slice state
interface AuthState {
  jwtToken: string | null;
  organizationId: string | null
}

// Define the initial state using that type
const initialState: AuthState = {
  jwtToken: localStorage.getItem('spv-jwt'),
  organizationId: localStorage.getItem('spv-organizationId'),
};

export const logIn = createAsyncThunk<{token: string; organizationId: string}, { username: string, password: string }>('auth/performLogin', async (payload, thunkAPI) => {
  const res = await authentication.authenticate({
    strategy: 'local',
    username: payload.username,
    password: payload.password,
  });
  // customHistory.push('/app');
  return {token: res.accessToken, organizationId: res.user.organization};
});

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    logOut: (state) => {
      return { ...state, jwtToken: null };
    },
  },
  extraReducers: builder => {
    builder.addCase(logIn.fulfilled, (state, action) => {
      return { ...state, jwtToken: action.payload.token, organizationId: action.payload.organizationId };
    });
  },
});

export const { logOut } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLoggedIn = (state: RootState) => !!state.auth.jwtToken;
export const selectOrgId = (state: RootState) => state.auth.organizationId;

export default authSlice.reducer;

