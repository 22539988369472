import React, { FunctionComponent, useCallback, useState } from 'react';
import CloseButton from '../../assets/closeButton.png';
import { Customer } from '../../model/customer';
import { customers } from '../../backend/backend';
import { cleanCustomers, loadCustomers, updateCustomer } from '../../features/customers/customersSlice';
import {useAppDispatch} from "../../redux/hooks";

const ClientCreateModal: FunctionComponent<{closeCallback: () => void, customer?: Customer}> = (props) => {
  const [customer, setCustomer] = useState<Customer>(props.customer || {});
  const dispatch = useAppDispatch();
  const isEdit = !!customer._id;
  const createCustomer = useCallback(async () => {
    if (isEdit) {
      // eslint-disable-next-line no-underscore-dangle
      const updatedCustomer = await customers.patch(customer._id!, customer);
      await dispatch(updateCustomer(updatedCustomer));
    } else {
      await customers.create(customer);
      dispatch(cleanCustomers());
      await dispatch(loadCustomers(false));
    }
  }, [customer]);
  return (
    <div className="fixed flex-row justify-center max-screen-xl flex w-1/2 h-2/3 min-w-min z-90 sm:rounded-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="flex-col flex w-1/2 rounded-l-lg min-w-min max-w-screen-xl bg-primary-600">
        {/* eslint-disable-next-line no-underscore-dangle */}
        <h2 className="font-bold text-primary-50 mt-4 mx-8 text-2xl">{customer._id ? 'MODIFICA CLIENTE' : 'NUOVO CLIENTE'}</h2>
        <div className="mx-8 mr-8 mt-auto mb-auto text-primary-50">
          <p>Qui puoi creare un nuovo cliente.<br /></p>
          <p className="sm:mt-4">Tutti i campi sono facoltativi tranne il nome.</p>
          <p className="sm:mt-4">Successivamente potrai associargli dei veicoli.</p>
        </div>
      </div>
      <div className="flex-col flex w-1/2 rounded-r-lg min-w-min max-w-screen-xl bg-gray-50">
        <button type="button" className="fixed top-2 right-2" onClick={props.closeCallback}>
          <img src={CloseButton} alt="close" />
        </button>
        <form
          className="space-y-3 mt-auto mb-auto px-8"
        >
          <div>
            <label htmlFor="name" className="sm:mx-8 block">
              Nome*
            </label>
            <div className="mt-1">
              <input
                value={customer.name || ''}
                onChange={({ target }) => {setCustomer({ ...customer, name: target.value })}}
                id="name"
                name="name"
                type="name"
                required
                className="ModalInput"
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="sm:mx-8 block">
              Email
            </label>
            <div className="mt-1">
              <input
                value={customer.email || ''}
                onChange={({ target }) => {setCustomer({ ...customer, email: target.value })}}
                id="email"
                name="email"
                type="email"
                className="ModalInput"
              />
            </div>
          </div>
          <div>
            <label htmlFor="address" className="sm:mx-8 block">
              Indirizzo
            </label>
            <div className="mt-1">
              <input
                value={customer.address || ''}
                onChange={({ target }) => {setCustomer({ ...customer, address: target.value })}}
                id="address"
                name="address"
                type="address"
                className="ModalInput"
              />
            </div>
          </div>
          <div>
            <label htmlFor="phone" className="sm:mx-8 block ">
              Numero di telefono
            </label>
            <div className="mt-1">
              <input
                value={customer.phoneNumber || ''}
                onChange={({ target }) => {setCustomer({ ...customer, phoneNumber: target.value })}}
                id="phone"
                name="phone"
                type="phone"
                className="ModalInput"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              onClick={async (event) => {
                event.preventDefault();
                await createCustomer();
                props.closeCallback();
              }}
              className="ConfirmCreateButton"
            >
              <span className="mx-auto">{isEdit ? 'SALVA' : 'CREA'}</span>
            </button>
          </div>
        </form>
      </div>
    </div>

  );
}
export default ClientCreateModal;
