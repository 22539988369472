import React, {FunctionComponent, useEffect, useState} from 'react'
import './Dashboard.scss'
import {Paginated} from '@feathersjs/feathers';
import TableComponent from '../../../components/dashboard/table.component';
import ClientsList from '../../../components/clients/clientsList.component';
import {customers, organizations, vehicles} from '../../../backend/backend';
import {Customer} from '../../../model/customer';
import {Vehicle} from '../../../model/vehicle';
import {Organization} from "../../../model/organization";
import {useAppSelector} from "../../../redux/hooks";
import {selectOrgId} from "../../../features/auth/authSlice";

async function fetchCustomers(): Promise<Paginated<Customer>> {
  return (await customers.find()) as Paginated<Customer>;
}

async function fetchVehicles(): Promise<Paginated<Vehicle>> {
  const r = (await vehicles.find()) as Paginated<Vehicle>;
  console.log(r);
  return r;
}

async function fetchDeadlines(organzationId: string): Promise<{deadline1: number; deadline2: number}> {
  const r = (await organizations.get(organzationId)) as Organization;
  return {deadline1: r.deadlines1mCount ?? 0, deadline2: r.deadlines2mCount ?? 0};
}


const Dashboard: FunctionComponent = () => {
  const [customersState, setCustomersState] = useState<Customer[]>([]);
  const [vehiclesState, setVehiclesState] = useState<Vehicle[]>([]);
  const orgId = useAppSelector(selectOrgId);
  const [deadlinesState, setDeadlinesState] = useState<{deadline1: number; deadline2: number}>({deadline1: 0, deadline2: 0});
  useEffect(() => {
    fetchCustomers().then((r) => {
      setCustomersState([ ...customersState, ...r.data]);
    });
    fetchVehicles().then((r) => {
      setVehiclesState([ ...vehiclesState, ...r.data]);
    });
    fetchDeadlines(orgId).then((r) => setDeadlinesState(r));
    // fetchInspections().then((r) => {
    //   setInspectionState([ ...inspectionState, ...r.data]);
    // });
  }, []);
  return (
    <div className="sm:mx-6">
      <div className="flex-row flex sm:mb-9">
        <h2 className="text-3xl font-bold text-primary-700 flex flex-col w-3/4">
          Scrivania
        </h2>
      </div>
      <div className="flex-row flex justify-center">
        <TableComponent deadlines={deadlinesState} />
      </div>
      <ClientsList />
    </div>
  );
}
export default Dashboard;
