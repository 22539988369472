import React, { FunctionComponent } from 'react'

import './table.component.scss'
import { Vehicle } from '../../model/vehicle';


const TableComponent: FunctionComponent<{deadlines: { deadline1: number; deadline2: number;}}> = props => {
  const date1 = new Date();
  const date2 = new Date();
  const monthNames = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

  date1.setMonth(date1.getMonth() + +1);
  date2.setMonth(date2.getMonth() + +2);

  const nextMonthName = monthNames[date1.getMonth()];
  const nextNextMonthName = monthNames[date2.getMonth()];

  // const expiringRevisions = (revisions: IDashboardVehicles[]) => {
  //   let num1: number=0;
  //   let num2: number=0;
  //   const dateNow:number = new Date().getMonth()
  //   revisions.map((revision) => {
  //     const revisionDate:number = new Date(revision?.revisione1).getMonth()
  //     if ((revisionDate - dateNow) === 1) {
  //       num1 += num1+1
  //     } else if ((revisionDate - dateNow) === 2) {
  //       num2 += num2+1
  //     }
  //   })
  //   return [num1, num2]
  // }

  return (
    <div className="sm:mx-auto flex-row sm:max-w-lg w-full space-x-16 sm:h-48 flex sm:mb-16 sm:mt-3">
      <div className="container w-full mx-auto bg-white-pure rounded-xl flex-col mt-4 shadow-sm">
        <div className="text-center sm:mt-4 text-2xl">
          {`${nextMonthName  } ${  date1.getFullYear()}`}
        </div>
        <div className="text-center sm:mt-3 text-black font-bold text-5xl text-primary-600">
          {props.deadlines.deadline1}
        </div>
        <div className="text-center sm:mt-5">
          REVISIONI IN SCADENZA
        </div>
      </div>
      <div className="container w-full bg-white-pure mr-auto rounded-xl flex-col flex mt-4 shadow-sm">
        <div className="text-center sm:mt-4 text-2xl">
          {`${nextNextMonthName  } ${  date2.getFullYear()}`}
        </div>
        <div className="text-center sm:mt-3 text-black font-bold text-5xl text-primary-600">
          {props.deadlines.deadline2}
        </div>
        <div className="text-center sm:mt-5">
          REVISIONI IN SCADENZA
        </div>
      </div>
    </div> );
}

export default TableComponent;
