// src/AppRouter.tsx
import React, { createElement, FunctionComponent, useCallback } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import Auth from './pages/auth/Auth/Auth';
import PrivateRoute from './helpers/PrivateRoute';
import Dashboard from './pages/app/dashboard/Dashboard';
import Clients from './pages/app/clients/Clients';
import Vehicles from './pages/app/vehicles/Vehicles';
import Reports from './pages/app/reports/Reports';
import { selectLoggedIn } from './features/auth/authSlice';
import ClientDetails from './pages/app/clients/clientDetails/clientDetails';
import AppLayout from './layout/app.layout';
import customHistory from './utils/history';
import { removeModal, selectModal, selectModalProps, selectShowBackdrop } from './features/modal/modalSlice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import modalEnumToComponent from './utils/modals';
import VehicleDetails from './pages/app/vehicles/vehicleDetails/vehicleDetails';

const AppRouter: FunctionComponent = (props) => {
  const isLoggedIn = useAppSelector(selectLoggedIn);
  const showBackdrop = useAppSelector(selectShowBackdrop);
  const modal = useAppSelector(selectModal);
  const modalProps = useAppSelector(selectModalProps);
  const dispatch = useAppDispatch();
  const handleBackdropClick = useCallback(async () => {
    dispatch(removeModal(),);
  }, []);
  return (
    <div>
      {showBackdrop && (
        <div>
          <div
            aria-hidden={true}
            onClick={handleBackdropClick}
            className="bg-gray-600 fixed z-80 w-full min-h-screen opacity-80"
          />
          {createElement<{closeCallback: () => void}>(modalEnumToComponent(modal), { closeCallback: handleBackdropClick, ...modalProps })}
        </div>)}
      <Router history={customHistory}>
        <Switch>
          <Route exact path="/">
            <Redirect to={isLoggedIn ? '/app/dashboard' : '/auth'} />
          </Route>
          <Route path="/auth" component={Auth} />
          <PrivateRoute path="/app" isAuthenticated={isLoggedIn} authenticationPath="/auth">
            <AppLayout>
              <Route exact path="/app">
                <Redirect to="/app/dashboard" />
              </Route>
              <Route exact path="/app/dashboard" component={Dashboard} />
              <Route exact path="/app/clients" component={Clients} />
              <Route path="/app/clients/:id" component={ClientDetails} />
              <Route exact path="/app/vehicles" component={Vehicles} />
              {/*<Route path="/app/vehicles/:id" component={VehicleDetails} />*/}
              <Route exact path="/app/reports" component={Reports} />
            </AppLayout>
          </PrivateRoute>
        </Switch>
      </Router>
    </div>
  );
};

export default AppRouter;
