import React, {FunctionComponent} from 'react'
import './Vehicles.scss'
import VehicleList from '../../../components/vehicles/vehiclescardlist.component';

import {VehicleSorting} from "../../../utils/vehicleSorting";
import {useAppDispatch} from "../../../redux/hooks";
import {setVehicleSorting} from "../../../features/vehicles/vehiclesSlice";


const Vehicles: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    return (
        <div className="sm:mx-6">
            <div className="flex-row flex sm:mb-9">
                <h2 className="text-3xl font-bold text-primary-700 flex flex-col w-3/4">
                    Veicoli
                </h2>
                <div className="flex-col flex">
                    <select
                        name="orderByVehicles"
                        id="orderByVehicles"
                        className="rounded-md"
                        onChange={({target}) => dispatch(setVehicleSorting(target.value as VehicleSorting))}
                    >
                        <option value={VehicleSorting.InspectionDate}>Scadenza</option>
                        <option value={VehicleSorting.Plate}>Targa</option>
                    </select>
                </div>
            </div>
            <VehicleList/>
        </div>
    );
}
export default Vehicles;
