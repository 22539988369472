import React, {FunctionComponent, useMemo, useState} from 'react';
import './Login.scss';

import { useHistory } from 'react-router-dom';
import {logIn, selectLoggedIn} from '../../../../features/auth/authSlice';
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";

const Login: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    // username: 'admin',
    // password: 'password',
    username: '',
    password: ''
  });
  const isLoggedIn = useAppSelector(selectLoggedIn);
  if(isLoggedIn) history.push('/app');
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-4xl font-bold text-primary-800">Accedi</h2>
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-xs">
        <div className="bg-white py-12 px-6 sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            onSubmit={async (event) => {
              event.preventDefault();
              dispatch(logIn({username: state.username, password: state.password}));
            }}
          >
            <div>
              <label htmlFor="username" className="block text-xs font-normal text-gray-400">
                Username
              </label>
              <div className="mt-1">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  className="LoginInput"
                  value={state.username}
                  onChange={(event) => setState({ ...state, username: event.target.value })}
                />
              </div>
            </div>
            <div>
              <label htmlFor="password" className="block text-xs font-normal text-gray-400">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="LoginInput"
                  value={state.password}
                  onChange={(event) => setState({ ...state, password: event.target.value })}
                />
              </div>
            </div>

            <div>
              <button disabled={!(state.password && state.username)} type="submit" className="LoginButton">
                ACCEDI
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
