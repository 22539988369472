import React from 'react'
import './revisionCheck.component.scss'
import green from '../assets/green.png';
import red from '../assets/red.png';
import yellow from '../assets/yellow.png';

export default class RevisionCheck extends React.PureComponent<IRevisionCheckProps, IRevisionCheckState> {

  constructor(props: IRevisionCheckProps) {
    super(props);
    this.state = {
      // TODO
    }
  }

  // If you need 'shouldComponentUpdate' -> Refactor to React.Component
  // Read more about component lifecycle in the official docs:
  // https://reactjs.org/docs/react-component.html

  /*
  public shouldComponentUpdate(nextProps: IMyPageProps, nextState: IMyPageState) {
    // invoked before rendering when new props or state are being received.
    return true // or prevent rendering: false
  } */

  static getDerivedStateFromProps:
  React.GetDerivedStateFromProps<IRevisionCheckProps, IRevisionCheckState> = (props:IRevisionCheckProps, state: IRevisionCheckState) => {
    // invoked right before calling the render method, both on the initial mount and on subsequent updates
    // return an object to update the state, or null to update nothing.
    return null
  }

  public getSnapshotBeforeUpdate(prevProps: IRevisionCheckProps, prevState: IRevisionCheckState) {
    // invoked right before the most recently rendered output is committed
    // A snapshot value (or null) should be returned.
    return null
  }

  componentDidUpdate(prevProps: IRevisionCheckProps, prevState: IRevisionCheckState, snapshot: ITemplateNameSnapshot) {
    // invoked immediately after updating occurs. This method is not called for the initial render.
    // will not be invoked if shouldComponentUpdate() returns false.
  }

  render() {
    return (
      <div>
        {(() => {
          if ((Math.round(new Date(this.props.revisione1).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) > 30) {
            return (
              <img height="16rem" width="16rem" src={green} alt="greencheck" />
            )
          }
          if (new Date() > new Date(this.props.revisione1)) {
            return (
              <img height="16rem" width="16rem" src={red} alt="redcheck" />
            )
          }
          return (
            <img height="16rem" width="16rem" src={yellow} alt="yellowcheck" />
          )
        })()}
      </div>
    )
  }
}

interface IRevisionCheckProps {
  revisione1: Date;
}

interface IRevisionCheckState {
  // TODO
}

interface ITemplateNameSnapshot {
  // TODO
}
