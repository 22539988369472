import React, {FunctionComponent} from 'react'
import {Route, useLocation} from 'react-router-dom';
import NavBar from '../components/navbar.component';
import ClientDetailsLeftSidebar from '../pages/app/clients/clientDetails/clientDetailsLeftSidebar';
import ClientDetailsRightSidebar from '../pages/app/clients/clientDetails/clientDetailsRightSidebar';
import ClientsLeftSidebar from '../pages/app/clients/clientsLeftSidebar';
import ClientsRightSidebar from '../pages/app/clients/clientsRightSidebar';
import VehicleDetailsLeftSidebar from '../pages/app/vehicles/vehicleDetails/vehicleDetailsLeftSidebar';
import VehicleDetailsRightSidebar from '../pages/app/vehicles/vehicleDetails/vehicleDetailsRightSidebar';
import VehiclesRightSidebar from "../pages/app/vehicles/vehiclesRightSidebar";
import VehiclesLeftSidebar from "../pages/app/vehicles/vehiclesLeftSidebar";
import {parse} from "query-string";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {
    selectVehiclesLoaded,
    setVehicleFirstInspectionFilter,
    setVehiclePlateFilter
} from "../features/vehicles/vehiclesSlice";
import {
    selectCustomersLoaded,
    setCustomerFirstInspectionFilter,
    setCustomerNameFilter
} from "../features/customers/customersSlice";
// const queryString = require('query-string');

const AppLayout: FunctionComponent = (props) => {
    const location = useLocation();
    const parsed = parse(location.search);
    const dispatch = useAppDispatch();
    const vehiclesLoaded = useAppSelector(selectVehiclesLoaded);
    const customersLoaded = useAppSelector(selectCustomersLoaded);
    if (parsed.vehiclePlate) {
        dispatch(setVehiclePlateFilter(parsed.vehiclePlate as string));
    }
    if (parsed.vehicleInspectionDate) {
        dispatch(setVehicleFirstInspectionFilter(new Date(parsed.vehicleInspectionDate as string)));
    }
    if (parsed.customerName) {
        dispatch(setCustomerNameFilter(parsed.customerName as string));
    }
    if (parsed.customerFirstInspection) {
        dispatch(setCustomerFirstInspectionFilter(new Date(parsed.vehicleInspectionDate as string)));
    }
    if (parsed.print && (vehiclesLoaded || customersLoaded)) {
        setTimeout(() => {
            window.print();
            window.onfocus=function(){ window.close();}
        }, 1000);
    }
    return (
        <div className="bg-gray-50">
            <NavBar/>
            <div className="flex-row justify-center flex">
                <div className="print:hidden flex-col flex w-1/4 pt-24 print:pt-0">
                    <Route exact path='/app/dashboard'>
                        {/* Left sidebar in dashboard */}
                        <div/>
                    </Route>
                    <Route exact path='/app/clients' component={ClientsLeftSidebar}/>
                    <Route path='/app/clients/:id' component={ClientDetailsLeftSidebar}/>
                    {/*<Route path='/app/vehicles/:id' component={VehicleDetailsLeftSidebar}/>*/}
                    <Route exact path='/app/vehicles' component={VehiclesLeftSidebar}/>

                    <Route path="/"/>
                </div>
                <div className="flex-col max-w-screen-lg w-1/2 print:w-full print:max-w-full flex bg-white min-h-screen pt-24 print:pt-0">
                    {props.children}
                </div>
                <div className="print:hidden flex-col flex w-1/4 pt-24 print:pt-0">
                    <Route exact path='/app/dashboard'>
                        {/* right sidebar in dashboard */}
                        <div/>
                    </Route>
                    <Route exact path='/app/clients' component={ClientsRightSidebar}/>
                    <Route path='/app/clients/:id' component={ClientDetailsRightSidebar}/>
                    {/*<Route path='/app/vehicles/:id' component={VehicleDetailsRightSidebar}/>*/}
                    <Route exact path='/app/vehicles' component={VehiclesRightSidebar}/>
                    <Route path="/"/>
                </div>
            </div>
        </div>
    );
}

export default AppLayout;
