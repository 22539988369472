import React, { FunctionComponent, useCallback } from 'react';
import { selectShowBackdrop, showModal } from '../../../features/modal/modalSlice';
import { Modals } from '../../../utils/modals';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import PrintButtonComponent from "../../../components/printButton.component";

const VehiclesLeftSidebar: FunctionComponent = () => {
  const showingBackdrop = useAppSelector(selectShowBackdrop);
  const dispatch = useAppDispatch();
  const showModalFn = useCallback(() => {
    dispatch(showModal({ modal: Modals.VehicleCreate, props: {} }));
  }, []);

  return (
    <div className="flex flex-col px-6 justify-center">
      <div className="flex flex-col space-y-6 max-w-xs mx-auto">
        <button type="button" className="PrimaryActionSidebarButton" onClick={!showingBackdrop ? showModalFn : undefined}>
          AGGIUNGI VEICOLO
        </button>
        <PrintButtonComponent />
      </div>
    </div>
  );
}
export default VehiclesLeftSidebar;
