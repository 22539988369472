// src/redux/store.ts

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { authSlice } from '../features/auth/authSlice';
import { modalSlice } from '../features/modal/modalSlice';
import { customersSlice } from '../features/customers/customersSlice';
import {vehiclesSlice} from "../features/vehicles/vehiclesSlice";

const logger = createLogger();

const store = configureStore({
  reducer: combineReducers({
    auth: authSlice.reducer,
    modal: modalSlice.reducer,
    customers: customersSlice.reducer,
    vehicles: vehiclesSlice.reducer,
    // your reducers goes here
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});


export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
