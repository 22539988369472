import React, {FunctionComponent} from 'react'

import './card.component.scss'


const CustomerCardComponent: FunctionComponent<ICardComponentProps> = (props) => {
    return (
        <div
            className="mx-auto flex-row flex mt-4 mb-6 py-6 print:py-0 print:mt-1 mr-auto w-full bg-white-pure rounded-md max-w-3xl transform print:transform-none hover:scale-105 shadow-sm print:shadow-none">
            <div className="flex-col flex mr-auto">
                <div className="text-2xl print:text-base flex text-gray font-bold mt-4 print:mt-0 mx-5">
                    {props.name}
                </div>
            </div>
            <div className="justify-center flex-col flex mr-4">
                <div className="flex-row space-x-4 flex">
                    <div className="flex-col mx-auto flex">
                        <div className="flex mx-auto text-2xl print:text-base font-bold text-gray-600">{props.revisionExpiringin1}</div>
                        <div className="text-sm mx-auto mr-auto flex font-light text-gray-600">TRA 1 MESE</div>
                    </div>
                    <div className="flex-col mx-auto flex">
                        <div className="flex mx-auto text-2xl print:text-base font-bold text-gray-600">{props.revisionExpiringin2}</div>
                        <div className="text-sm mx-auto flex mr-auto font-light text-gray-600">TRA 2 MESI</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerCardComponent;

interface ICardComponentProps {
    name: string;
    revisionExpiringin1: number;
    revisionExpiringin2: number;

}
