import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Paginated } from '@feathersjs/feathers';
import type { RootState } from '../../redux/store';
import {vehicles} from '../../backend/backend';
import {Vehicle} from "../../model/vehicle";
import {VehicleSorting} from "../../utils/vehicleSorting";

// Define a type for the slice state
interface VehiclesState {
  vehicles: Vehicle[],
  pagination: {
    total: number;
    limit: number;
    skip: number;
  },
  filters: {
    nextInspectionDate?: Date;
    plate?: string;
  }
  selectedVehicle?: Vehicle,
  sorting: VehicleSorting,
  // sortingDirection: 1|-1
}

// Define the initial state using that type
const initialState: VehiclesState = {
  vehicles: [],
  pagination: {
    limit: 10,
    total: -1,
    skip: 0
  },
  filters: {},
  sorting: VehicleSorting.InspectionDate,
  // sortingDirection: 1
};

export const loadVehicle = createAsyncThunk<Vehicle, { id: string }>('vehicles/loadVehicle', async (payload, thunkAPI) => {
  const vehicle = await vehicles.get(payload.id);
  return vehicle;
});

export const loadVehicles = createAsyncThunk<Paginated<Vehicle> | Vehicle[], {loadAll?: boolean, customerId?: string} | undefined>('vehicles/loadVehicles', async (params, thunkAPI) => {
  const draftState = thunkAPI.getState() as RootState;
  const query: {customer?: string, $populate?: string[], $paginate?: boolean, $skip?: number, $limit?: number, plate?: { $search: string }, nextInspectionDate?: { $lte?: Date, $gte?: Date }, $sort?: {plate?: 1|-1, nextInspectionDate?: 1|-1}} = {
  };
  if (params?.loadAll) {
    query.$paginate = false;
  } else {
    if (draftState.vehicles.pagination.skip) {
      query.$skip = draftState.vehicles.pagination.skip;
    }
    if (draftState.vehicles.pagination.limit) {
      query.$limit = draftState.vehicles.pagination.limit;
    }
  }
  if (!params?.customerId) {
    query.$populate = ['customer'];
  } else {
    query.customer = params.customerId
  }
  if (draftState.vehicles.filters.plate) {
    query.plate = {
      $search: draftState.vehicles.filters.plate
    };
  }
  if (draftState.vehicles.filters.nextInspectionDate) {
    query.nextInspectionDate = {
      // $gte: new Date(),
      $lte: draftState.vehicles.filters.nextInspectionDate
    };
  }
  switch (draftState.vehicles.sorting) {
    case VehicleSorting.Plate:
      query.$sort = {
        plate: 1
      }
      break;
    case VehicleSorting.InspectionDate:
      query.$sort = {
        nextInspectionDate: 1
      }
      break;
    default:
      query.$sort = {
        nextInspectionDate: 1
      }
      break;
  }
  return (await vehicles.find({ query })) as Paginated<Vehicle>;
});

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    setVehicleSorting: (state, action: PayloadAction<VehicleSorting>) => {
      return { ...state, sorting: action.payload, pagination: { ...initialState.pagination }, vehicles: [] };
    },
    setSelectedVehicle: (state, action: PayloadAction<Vehicle>) => {
      return { ...state, selectedVehicle: action.payload };
    },
    setVehiclePlateFilter: (state, action: PayloadAction<string | undefined>) => {
      return { ...state, vehicles: [], filters: { ...state.filters, plate: action.payload }, pagination: { ...initialState.pagination } };
    },
    setVehicleFirstInspectionFilter: (state, action: PayloadAction<Date | undefined | null>) => {
      return { ...state, vehicles: [], filters: { ...state.filters, firstInspection: action.payload || undefined }, pagination: { ...initialState.pagination } };
    },
    cleanVehicles: (state) => {
      return { ...state, vehicles: [], pagination: { ...initialState.pagination }, filters: {...initialState.filters} } ;
    },
    cleanVehicle: (state) => {
      return { ...state, selectedVehicle: undefined};
    },
    updateVehicle: (state, action: PayloadAction<Vehicle>) => {
      // eslint-disable-next-line no-underscore-dangle
      const index = state.vehicles.findIndex((e) => e._id === action.payload._id);
      if (index !== -1) {
        const newVehicles = [...state.vehicles];
        newVehicles[index] = action.payload;
        return {
          ...state,
          vehicles: newVehicles,
          // eslint-disable-next-line no-underscore-dangle
          selectedVehicle: state.selectedVehicle?._id === action.payload._id ? action.payload : state.selectedVehicle
        };
      }
      return {
        ...state,
        // eslint-disable-next-line no-underscore-dangle
        selectedVehicle: state.selectedVehicle?._id === action.payload._id ? action.payload : state.selectedVehicle
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(loadVehicle.fulfilled, (state, action) => {
      return { ...state, selectedVehicle: action.payload };
    });
    builder.addCase(loadVehicles.fulfilled, (state, action) => {
      if ('data' in action. payload) {
        return {
          ...state, vehicles: action.payload.skip ? [...state.vehicles, ...action.payload.data] : action.payload.data,
          pagination: {
            ...state.pagination,
            skip: action.payload.skip + action.payload.data.length,
            total: action.payload.total
          }
        };
      } else {
        return {
          ...state, vehicles: action.payload,
          pagination: initialState.pagination
        };
      }
    });
  },
});

export const {
  setVehicleFirstInspectionFilter,
    cleanVehicles,
    setSelectedVehicle,
    setVehiclePlateFilter,
    updateVehicle,
    cleanVehicle,
  setVehicleSorting
} = vehiclesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectVehicle = (state: RootState) => state.vehicles.selectedVehicle;
export const selectVehicles = (state: RootState) => state.vehicles.vehicles;
export const selectVehiclesLoaded = (state: RootState) => !!state.vehicles.vehicles.length;
export const selectVehiclesSorting = (state: RootState) => state.vehicles.sorting;
// export const selectVehiclesSortingDirection = (state: RootState) => state.Vehicles.sortingDirection;
export const selectVehiclesPagination = (state: RootState) => state.vehicles.pagination;
export const selectVehiclesNameFilter = (state: RootState) => state.vehicles.filters.name;
export const selectVehiclesFilter = (state: RootState) => state.vehicles.filters;
export const selectVehiclesFirstInspectionFilter = (state: RootState) => state.vehicles.filters.firstInspection;

export default vehiclesSlice.reducer;
