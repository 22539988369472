import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Paginated } from '@feathersjs/feathers';
import type { RootState } from '../../redux/store';
import { Customer } from '../../model/customer';
import { customers } from '../../backend/backend';
import { CustomerSorting } from '../../utils/customerSorting';

// Define a type for the slice state
interface CustomersState {
  customers: Customer[],
  pagination: {
    total: number;
    limit: number;
    skip: number;
  },
  filters: {
    firstInspection?: Date;
    name?: string;
  }
  selectedCustomer?: Customer,
  sorting: CustomerSorting,
  // sortingDirection: 1|-1
}

// Define the initial state using that type
const initialState: CustomersState = {
  customers: [],
  pagination: {
    limit: 10,
    total: -1,
    skip: 0
  },
  filters: {},
  sorting: CustomerSorting.Name,
  // sortingDirection: 1
};

export const loadCustomer = createAsyncThunk<Customer, { id: string }>('customers/loadCustomer', async (payload, thunkAPI) => {
  const customer = await customers.get(payload.id);
  return customer;
});

export const loadCustomers = createAsyncThunk<Paginated<Customer> | Customer[], boolean| undefined>('customers/loadCustomers', async (loadAll = false, thunkAPI) => {
  const draftState = thunkAPI.getState() as RootState;
  const query: { $paginate?: boolean, $skip?: number, $limit?: number, iname?: { $search: string }, nextInspectionDate?: { $lte?: Date, $gte?: Date }, $sort?: {iname?: 1|-1, numVehicles?: 1|-1}} = {
  };
  if (loadAll) {
    query.$paginate = false;
  } else {
    if (draftState.customers.pagination.skip) {
      query.$skip = draftState.customers.pagination.skip;
    }
    if (draftState.customers.pagination.limit) {
      query.$limit = draftState.customers.pagination.limit;
    }
  }
  if (draftState.customers.filters.name) {
    query.iname = {
      $search: draftState.customers.filters.name
    };
  }
  if (draftState.customers.filters.firstInspection) {
    query.nextInspectionDate = {
      // $gte: new Date(),
      $lte: draftState.customers.filters.firstInspection
    };
  }
  switch (draftState.customers.sorting) {
    case CustomerSorting.Name:
      query.$sort = {
        iname: 1
      }
      break;
    case CustomerSorting.Vehicles:
      query.$sort = {
        numVehicles: -1
      }
      break;
  }
  return (await customers.find({ query })) as Paginated<Customer>;
});

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<CustomerSorting>) => {
      return { ...state, sorting: action.payload };
    },
    setSelectedCustomer: (state, action: PayloadAction<Customer>) => {
      return { ...state, selectedCustomer: action.payload };
    },
    setCustomerNameFilter: (state, action: PayloadAction<string | undefined>) => {
      return { ...state, filters: { ...state.filters, name: action.payload }, pagination: { ...initialState.pagination }, customers: [] };
    },
    setCustomerFirstInspectionFilter: (state, action: PayloadAction<Date | undefined | null>) => {
      return { ...state, filters: { ...state.filters, firstInspection: action.payload || undefined }, pagination: { ...initialState.pagination }, customers: [] };
    },
    cleanCustomers: (state) => {
      return { ...state, customers: [], pagination: { ...initialState.pagination }, filters: {...initialState.filters} };
    },
    cleanCustomer: (state) => {
      return { ...state, selectedCustomer: undefined,};
    },
    updateCustomer: (state, action: PayloadAction<Customer>) => {
      // eslint-disable-next-line no-underscore-dangle
      const index = state.customers.findIndex((e) => e._id === action.payload._id);
      if (index !== -1) {
        const newCustomers = [...state.customers];
        newCustomers[index] = action.payload;
        return {
          ...state,
          customers: newCustomers,
          // eslint-disable-next-line no-underscore-dangle
          selectedCustomer: state.selectedCustomer?._id === action.payload._id ? action.payload : state.selectedCustomer
        };
      }
      return {
        ...state,
        // eslint-disable-next-line no-underscore-dangle
        selectedCustomer: state.selectedCustomer?._id === action.payload._id ? action.payload : state.selectedCustomer
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(loadCustomer.fulfilled, (state, action) => {
      return { ...state, selectedCustomer: action.payload };
    });
    builder.addCase(loadCustomers.fulfilled, (state, action) => {
      if ('data' in action.payload) {
        return {
          ...state, customers: action.payload.skip ? [...state.customers, ...action.payload.data] : action.payload.data,
          pagination: {
            ...state.pagination,
            skip: action.payload.skip + action.payload.data.length,
            total: action.payload.total
          }
        };
      } else {
        return {
          ...state,
          customers: action.payload,
          pagination: initialState.pagination
        };
      }
    });
  },
});

export const {
  setSelectedCustomer,
  setCustomerNameFilter,
  setCustomerFirstInspectionFilter,
  updateCustomer,
  cleanCustomers,
    cleanCustomer,
  setSorting
} = customersSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCustomer = (state: RootState) => state.customers.selectedCustomer;
export const selectCustomers = (state: RootState) => state.customers.customers;
export const selectCustomersLoaded = (state: RootState) => !!state.customers.customers.length;
export const selectCustomersSorting = (state: RootState) => state.customers.sorting;
// export const selectCustomersSortingDirection = (state: RootState) => state.customers.sortingDirection;
export const selectCustomersPagination = (state: RootState) => state.customers.pagination;
export const selectCustomersNameFilter = (state: RootState) => state.customers.filters.name;
export const selectCustomersFilter = (state: RootState) => state.customers.filters;
export const selectCustomersFirstInspectionFilter = (state: RootState) => state.customers.filters.firstInspection;

export default customersSlice.reducer;
