import React, { FunctionComponent, useCallback } from 'react';
import { selectShowBackdrop, showModal } from '../../../../features/modal/modalSlice';
import { Modals } from '../../../../utils/modals';
import { selectCustomer } from '../../../../features/customers/customersSlice';
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import PrintButtonComponent from "../../../../components/printButton.component";

const ClientDetailsLeftSidebar: FunctionComponent = () => {
  const showingBackdrop = useAppSelector(selectShowBackdrop);
  const selectedCustomer = useAppSelector(selectCustomer);
  const dispatch = useAppDispatch();
  const showModalFn = useCallback(() => {
    dispatch(showModal({ modal: Modals.VehicleCreate, props: { customer: selectedCustomer } }));
  }, [selectedCustomer]);
  const showModalEdit = useCallback(() => {
    dispatch(showModal({ modal: Modals.ClientCreate, props:  { customer: selectedCustomer } }));
  }, [selectedCustomer]);


  return (
    <div className="flex flex-col px-6 justify-center">
      <div className="flex flex-col space-y-6 max-w-xs mx-auto">
        <button type="button" className="PrimaryActionSidebarButton" onClick={!showingBackdrop ? showModalFn : undefined}>
          AGGIUNGI VEICOLO
        </button>
        <PrintButtonComponent />
        <button type="button" className="TertiaryActionSidebarButton" onClick={!showingBackdrop ? showModalEdit : undefined}>
          MODIFICA
        </button>
      </div>
    </div>
  );
}
export default ClientDetailsLeftSidebar;
