import React, {FunctionComponent} from "react";
import {Link, useLocation} from "react-router-dom";
import {parse, stringifyUrl} from "query-string";

const PrintButtonComponent: FunctionComponent = () => {
    const location = useLocation();
    const parsedQueryParams = parse(location.search);
    const newUrl = stringifyUrl({
        url: location.pathname,
        query: {
            ...parsedQueryParams,
            print: 1
        }
    });
    return (
        <Link to={newUrl} target="_blank" className="SecondaryActionSidebarButton">
            STAMPA
        </Link>
    );
}
export default PrintButtonComponent;
