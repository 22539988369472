import React, {FunctionComponent, useCallback, useEffect} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './clientsList.component.scss';
import {Link, useLocation} from 'react-router-dom';
import CustomerCardComponent from './card.component';
import {
    cleanCustomers,
    loadCustomers,
    selectCustomers,
    selectCustomersFilter,
    selectCustomersPagination,
    selectCustomersSorting
} from '../../features/customers/customersSlice';
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {Customer} from "../../model/customer";
import {parse} from "query-string";


const ClientsList: FunctionComponent = (props) => {
    const customersState = useAppSelector<Customer[]>(selectCustomers);
    const paginationState = useAppSelector(selectCustomersPagination);
    const filters = useAppSelector(selectCustomersFilter);
    const sorting = useAppSelector(selectCustomersSorting);
    const dispatch = useAppDispatch();

    const location = useLocation();

    const isPrinting = parse(location.search).print === '1';

    useEffect(() => () => {
        dispatch(cleanCustomers());
    }, []);

    const loadNext = useCallback((): void => {
        dispatch(loadCustomers(false));
    }, []);

    useEffect(() => {
        dispatch(loadCustomers(isPrinting));
    }, [filters, sorting, isPrinting]);
    return (
        <InfiniteScroll
            next={!isPrinting ? loadNext : () => {
            }}
            hasMore={!isPrinting && customersState.length < paginationState.total}
            loader={<h4>Caricamento...</h4>}
            dataLength={customersState.length}
        >
            {
                customersState.map((customer: Customer) => (
                    // eslint-disable-next-line no-underscore-dangle
                    <div key={customer._id}>
                        <Link to={`/app/clients/${customer._id}`}>
                            <CustomerCardComponent
                                name={customer.name!}
                                revisionExpiringin1={customer.deadlines1mCount || 0}
                                revisionExpiringin2={customer.deadlines2mCount || 0}
                            />
                        </Link>
                        <hr className="hidden print:block"/>
                    </div>
                ))
            }
        </InfiniteScroll>
    );
};
export default ClientsList;
