import React, { FunctionComponent, useEffect, useState } from 'react'
import './navbar.component.scss'
import { Paginated } from '@feathersjs/feathers';
import {Link, useLocation} from 'react-router-dom';
import { Vehicle } from '../model/vehicle';
import { vehicles } from '../backend/backend';
import logo from '../assets/vehicles.png'
import history from "../utils/history";
import {parse, stringify} from "query-string";

const searchVehicles = async (searchTerm: string) => {
  if (searchTerm) {
    const r = await vehicles.find({ query: { 'plate[$search]': searchTerm } }) as Paginated<Vehicle>;
    return r.data;
  }
  return [];
}

const NavBar: FunctionComponent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  }
  const [suggestedVehicles, setSuggestedVehicles] = useState<Vehicle[]>([]);
  useEffect(() => {
    searchVehicles(searchTerm).then((v) => {setSuggestedVehicles(v)});
  }, [searchTerm]);
  return (
    <nav className="NavBar flex items-center bg-primary-400 h-16 fixed w-full z-50">
      <div className="flex flex-row mx-5 w-1/3 text-white">
        <Link to="/" className="flex flex-row space-x-3 text-white hover:text-primary-800">
          <img src={logo} alt="vehicles" />
          <span>Studio Portovado</span>
        </Link>
      </div>
      <div className="sm:max-w-md w-1/3">
        <input
          className="SearchInput"
          placeholder="Ricerca targhe"
          type="search"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className="VehicleSuggestions bg-white-pure rounded-xl shadow-sm absolute w-full sm:max-w-md mt-2 space-y-2 px-4 py-2">
          {suggestedVehicles.map((v) => (
            // eslint-disable-next-line no-underscore-dangle
            <Link to={`/app/clients/${v?.customer}`} key={v._id} onClick={() => { setSearchTerm('') }}>
              {/* eslint-disable-next-line no-underscore-dangle */}
              <div key={v._id} className="flex flex-row py-2">
                <span>{v.plate}</span>
              </div>
            </Link>
            // eslint-disable-next-line no-underscore-dangle
          ))}
        </div>
      </div>
      <div className="flex flex-row justify-evenly w-1/3">
        <Link to="/app/clients" className="text-white hover:text-primary-800">
          <span>Clienti</span>
        </Link>
        <Link to="/app/vehicles" className="text-white hover:text-primary-800">
          <span>Veicoli</span>
        </Link>
      </div>
    </nav>
  );
}

export default NavBar;
