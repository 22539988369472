import React, { FunctionComponent } from 'react';
import {useAppSelector} from "../../../../redux/hooks";
import {selectCustomer} from "../../../../features/customers/customersSlice";
import {Customer} from "../../../../model/customer";


const ClientDetailsRightSidebar: FunctionComponent = () => {
    const customerState = useAppSelector<Customer>(selectCustomer);
  return (
      <div className="flex flex-col px-6 space-y-12">
        <h2>Contatti</h2>
        <div className="flex flex-col space-y-6">
            <div className="flex flex-col">
                <label htmlFor="address">Indirizzo</label>
                <span className="text-gray-600" id="address">{customerState?.address}</span>
            </div>
            <div className="flex flex-col">
                <label htmlFor="address">Email</label>
                <span className="text-gray-600" id="address">{customerState?.email}</span>
            </div>
            <div className="flex flex-col">
                <label htmlFor="address">Numero di telefono</label>
                <span className="text-gray-600" id="address">{customerState?.phoneNumber}</span>
            </div>
        </div>
      </div>
  );
}
export default ClientDetailsRightSidebar;
