import React, {FunctionComponent, useEffect, useState} from 'react';
import moment from 'moment';
import {useDebouncedCallback} from 'use-debounce';
import {
    selectCustomersFirstInspectionFilter,
    selectCustomersNameFilter,
} from '../../../features/customers/customersSlice';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
    selectVehiclesFirstInspectionFilter,
    selectVehiclesNameFilter,
    setVehicleFirstInspectionFilter,
    setVehiclePlateFilter
} from "../../../features/vehicles/vehiclesSlice";
import {useLocation} from "react-router-dom";
import history from "../../../utils/history";
import {parse, stringify} from "query-string";

const VehiclesRightSidebar: FunctionComponent = () => {
    const initialName = useAppSelector(selectVehiclesNameFilter);
    const initialFirstInspection = useAppSelector(selectVehiclesFirstInspectionFilter);
    const [name, setName] = useState(initialName);
    const [firstInspection, setFirstInspection] = useState(initialFirstInspection);

    useEffect(() => {
        setName(initialName);
        setFirstInspection(initialFirstInspection);
    }, [initialFirstInspection, initialName]);

    const location = useLocation();

    // Debounce callback
    const debouncedSetPlate = useDebouncedCallback(
        // function
        (value) => {
            history.push({search: stringify({...parse(location.search), vehiclePlate: value})})
            dispatch(setVehiclePlateFilter(value))
        },
        // delay in ms
        300
    );
    const debouncedSetFirstInspection = useDebouncedCallback(
        // function
        (value) => {
            history.push({search: stringify({...parse(location.search), vehicleInspectionDate: moment(value).format('YYYY-MM-DD')})})
            dispatch(setVehicleFirstInspectionFilter(value))
        },
        // delay in ms
        300
    );
    useEffect(() => () => {
        history.push({search: ''});
    }, [] );
    const dispatch = useAppDispatch();
    return (
        <div className="flex flex-col px-6 space-y-12">
            <h2>Filtri</h2>
            <div className="flex flex-col space-y-4">
                <div className="space-y-2">
                    <label htmlFor="name" className="block ">
                        Targa
                    </label>
                    <input
                        id="name"
                        value={name || ''}
                        className="FilterSearch"
                        placeholder="Targa"
                        type="search"
                        onChange={({target}) => {
                            setName(target.value);
                            debouncedSetPlate(target.value);
                        }}
                    />
                </div>
                <div className="space-y-2">
                    <label htmlFor="firstInspection" className="block ">
                        Scadenza entro il
                    </label>
                    <input
                        id="firstInspection"
                        value={firstInspection ? moment(firstInspection).format('YYYY-MM-DD') : ''}
                        className="FilterSearch"
                        placeholder="Revisione in scadenza entro"
                        type="date"
                        onChange={({target}) => {
                            setFirstInspection(target.valueAsDate);
                            debouncedSetFirstInspection(target.valueAsDate);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
export default VehiclesRightSidebar;
