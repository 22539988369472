import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import {Paginated} from '@feathersjs/feathers';
import CloseButton from '../../assets/closeButton.png';
import {Customer} from '../../model/customer';
import {customers, vehicles} from '../../backend/backend';
import {Vehicle} from '../../model/vehicle';
import {useAppDispatch} from "../../redux/hooks";
import {cleanVehicles, loadVehicles} from "../../features/vehicles/vehiclesSlice";
import {loadCustomers} from "../../features/customers/customersSlice";

const clientOptions = async (inputValue: any) => {
    const r = (await customers.find({query: {iname: {$search: inputValue}}})) as Paginated<Customer>;
    return r.data.map((e) => ({value: e, label: e.name}));
};

const VehicleCreateModal: FunctionComponent<{ closeCallback: () => void, customer?: Customer, vehicle?: Vehicle }> = (props) => {
    const [vehicle, setVehicle] = useState<Vehicle>(props.vehicle || {customer: props.customer});
    const dispatch = useAppDispatch();
    const createVehicle = useCallback(async () => {
        if (isEdit) {
            // @ts-ignore
            const updatedVehicle = vehicles.patch(vehicle._id, vehicle);
        } else {
            await vehicles.create(vehicle);
        }
        dispatch(cleanVehicles());
        await dispatch(loadVehicles());
    }, [vehicle]);
    const deleteVehicle = useCallback(async () => {
        // @ts-ignore
        await vehicles.remove(vehicle._id);
        dispatch(cleanVehicles());
        await dispatch(loadVehicles());
    }, []);
    const [editClientEnabled, setEditClientEnabled] = useState(!vehicle.customer);
    const isEdit = !!vehicle._id;

    return (
        <div
            className="fixed flex-row justify-center max-screen-xl flex w-1/2 h-2/3 min-w-min z-90 sm:rounded-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
            <div className="flex-col flex w-1/2 rounded-l-lg min-w-min max-w-screen-xl bg-primary-600">
                <h2 className="font-bold text-primary-50 mt-4 mx-8 text-2xl">{isEdit ? 'Modifica' : 'Nuovo'} veicolo</h2>
                {isEdit ?
                    <div/>
                    :
                    <div className="mx-8 mr-8 mt-auto mb-auto text-primary-50">
                        <p>Qui puoi creare un nuovo veicolo.<br/></p>
                        <p className="sm:mt-4">Puoi associarlo da subito a un cliente.</p>
                    </div>
                }
            </div>
            <div className="flex-col flex w-1/2 rounded-r-lg min-w-min max-w-screen-xl bg-gray-50">
                <button type="button" className="fixed top-2 right-2" onClick={props.closeCallback}>
                    <img src={CloseButton} alt="close"/>
                </button>
                <form
                    className="space-y-6 mt-auto mb-auto px-8"
                >
                    <div>
                        <label htmlFor="plate" className="block text-xs font-normal text-gray-400">
                            Targa*
                        </label>
                        <div className="mt-1">
                            <input
                                value={vehicle.plate || ''}
                                onChange={({target}) => {
                                    setVehicle({...vehicle, plate: target.value});
                                }}
                                id="plate"
                                name="plate"
                                type="plate"
                                required
                                className="ModalInput"
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="nextInspectionDate" className="block text-xs font-normal text-gray-400">
                            Scadenza revisione
                        </label>
                        <div className="mt-1">
                            <input
                                value={vehicle.nextInspectionDate ? moment(vehicle.nextInspectionDate).format('YYYY-MM-DD') : ''}
                                onChange={({target}) => {
                                    setVehicle({...vehicle, nextInspectionDate: target.valueAsDate});
                                }}
                                id="nextInspectionDate"
                                name="nextInspectionDate"
                                type="date"
                                className="ModalInput"
                            />
                        </div>
                    </div>
                    {editClientEnabled &&
                    <div>
                        <label htmlFor="customer" className="block text-xs font-normal text-gray-400">
                            Cliente
                        </label>
                        <div className="mt-1">
                            <AsyncSelect
                                onChange={(value) => {
                                    setVehicle({...vehicle, customer: value?.value});
                                }}
                                value={vehicle.customer ? {
                                    value: (vehicle.customer as Customer),
                                    label: (vehicle.customer as Customer)?.name
                                } : {}}
                                classNamePrefix="ModalInput"
                                loadOptions={clientOptions}
                            />
                        </div>
                    </div>}

                    <div className="flex flex-col space-y-0 justify-start space-y-8">
                        <div>
                            <button
                                type="submit"
                                onClick={async (event) => {
                                    event.preventDefault();
                                    await createVehicle();
                                    props.closeCallback();
                                }}
                                className="ConfirmCreateButton"
                            >
                                <span className="mx-auto">{isEdit ? 'SALVA' : 'CREA'}</span>
                            </button>
                        </div>
                        {isEdit &&
                        <div>
                            <button
                                type="submit"
                                onClick={async (event) => {
                                    event.preventDefault();
                                    await deleteVehicle();
                                    props.closeCallback();
                                }}
                                className="DeleteButton"
                            >
                                <span className="mx-auto">ELIMINA</span>
                            </button>
                        </div>
                        }
                    </div>
                </form>
            </div>
        </div>

    );
};
export default VehicleCreateModal;
